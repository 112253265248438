// Here you can add other styles

.ngx-pagination {
  padding-left: 0;
}

.overflow-x-auto {
  overflow-x: auto !important
}

.max-length-indicator {
  font-size: 12px;
  color: #999;
}