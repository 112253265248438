@import "src/assets/css/variable.scss";

// If you want to add something do it here
@import "src/assets/css/custom.scss";

@font-face {
  font-family: 'Tahoma';
  src: url('./assets/fonts/Tahoma.eot');
  src: url('./assets/fonts/Tahoma.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Tahoma.woff2') format('woff2'),
    url('./assets/fonts/Tahoma.woff') format('woff'),
    url('./assets/fonts/Tahoma.ttf') format('truetype'),
    url('./assets/fonts/Tahoma.svg#Tahoma') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tahoma';
  src: url('./assets/fonts/Tahoma-Bold.eot');
  src: url('./assets/fonts/Tahoma-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Tahoma-Bold.woff2') format('woff2'),
    url('./assets/fonts/Tahoma-Bold.woff') format('woff'),
    url('./assets/fonts/Tahoma-Bold.ttf') format('truetype'),
    url('./assets/fonts/Tahoma-Bold.svg#Tahoma-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html {
  overflow: hidden;
}


body {
  font-family: 'Tahoma';
  font-weight: normal;
  font-style: normal;
}

img {
  max-width: 100%;
}

h2 {
  font-size: 44px;
  font-weight: bold;
  margin: 0;

  @media screen and (max-width: $tab-screen) {
    font-size: 36px;
  }

  @media screen and (max-width: $tabSmall-screen) {
    font-size: 32px;
  }

  @media screen and (max-width: $mobile-screen) {
    font-size: calc(1.325rem + .9vw);
  }
}

h3,
h4,
h5 {
  font-weight: bold;
}



.btn {
  @extend %default-btn;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 12px;
}

.btn-theme {
  @include btn-theme(#fff, #f36b21, #bd541b);
}

.btn-themeNew {
  @include btn-theme(#fff, #a91f2e, #a91f2e);
}

.btn-orange {
  @include btn-theme(#fff, #f36b21, #bd541b);
}

.btn-red {
  @include btn-theme(#fff, #a91f2e, #f36b21);
}

.btn-grayTheme {
  @include btn-theme(#fff, #8c8c8c, #f36b21);
}

.btn-link {
  color: #a91f2e;
  text-decoration: none;
}

.btn-link:hover {
  color: #bd541b;
}

.h-100vh {
  height: 100vh;
}

.mb-30 {
  margin-bottom: 30px;
}

.mnw-auto {
  min-width: auto !important;
}

.flex-center {
  @include flex-all;
}


/** Reused Styles */
.box {
  padding: 70px 58px;
  box-shadow: 0 5px 12px 4px #f1f1f1;

  @media screen and (max-width: $tab-screen) {
    padding: 57px 40px;
  }


  @media screen and (max-width: $tabSmall-screen) {
    padding: 48px 28px;
  }

  @media screen and (max-width: $sm-screen) {
    padding: 36px 12px;
  }
}

.login-box {
  p {
    font-size: 18px;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 0;

    @media screen and (max-width: $tab-screen) {
      font-size: 18px;
      font-size: 18px;
    }

    @media screen and (max-width: $mobile-screen) {
      font-size: 16px;
      font-size: 16px;
    }
  }

  .btn {
    font-weight: bold;
    padding: 18px 20px;
    font-size: 16px !important;

    @media screen and (max-width: $mobile-screen) {
      padding: 14px 20px;
    }
  }

  .btn-sm {
    padding: 12px 10px;
    font-size: 14px !important;
  }
}



.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f36b21;
  --bs-btn-border-color: #f36b21;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #eb5b0e;
  --bs-btn-hover-border-color: #f36b21;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #eb5b0e;
  --bs-btn-active-border-color: #f36b21;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c0c0c0;
  --bs-btn-disabled-color: #c0c0c0;
  --bs-btn-disabled-bg: #f36b21;
  --bs-btn-disabled-border-color: #f36b21;


}

.bg-gray {
  background: #eee;
}

.text-gray {
  color: #cdcdcd;
}

.text-gray {
  color: #cdcdcd;
}

.form-floating>.form-control {
  font-size: 16px;
  font-size: 16px;
  letter-spacing: .32px;
  height: 60px;

  @media screen and (max-width: $mobile-screen) {
    height: auto;
  }
}

.form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-control-plaintext:-webkit-autofill,
.form-floating>.form-control:-webkit-autofill {
  padding: 18.5px 14px;
}

.form-floating>label {
  height: auto;
  display: block;
  left: 10px;
  transform: none !important;
}

.form-control:focus,
.form-select:focus {
  outline: none !important;
  border-color: #dfe2e7 !important;
  box-shadow: none !important;
}

.form-select.is-invalid:focus,
.form-control.is-invalid:focus {
  border-color: #f40000 !important;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after {
  display: none;
}

.form-floating>.form-control:disabled:focus~label,
.form-floating>.form-control:disabled:not(:placeholder-shown)~label,
.form-floating>.form-control:disabled:-webkit-autofill~label {
  background: transparent;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control:-webkit-autofill~label {
  padding: 2px 7px;
  background: #fff;
  transform: scale(.85) translateY(-0.8rem) translateX(.15rem) !important;
}

.input-group {
  .form-floating>.form-control {
    border-right: 0;

  }

}

.input-group-text {
  background: transparent;

  svg {
    width: 41px;

    @media screen and (max-width: $mobile-screen) {
      width: 28px;
    }
  }
}

.tab-theme {
  .tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    margin-bottom: 30px;

    a {
      display: inline-flex;
      color: #495057;
      background-color: #f2f3f5;
      font-size: 18px;
      font-weight: 400;
      padding: 10px 22px;
      text-decoration: none;
      position: relative;
      margin: 0 24px 15px 0;
      border-radius: 8px;

      &.active {
        color: #fff;
        background-color: #f36b21;
      }

      @media screen and (max-width: $tab-screen) {
        font-size: 18px;
        padding: 12px 22px;
        padding: 12px 22px;
      }

      @media screen and (max-width: $tabSmall-screen) {
        font-size: 16px;
      }

      span {
        @include flex-all;
        padding: 0 18px;
        height: 100%;
        background-size: 100% 100%;

        @media screen and (max-width: $tab-screen) {
          padding: 0 12px;
        }
      }
    }
  }
}

.theme-box {
  box-shadow: -1px 2px 20px #f1f1f1;

  @media screen and (max-width: $laptopSmall-screen) {
    padding: 24px 30px;

  }

  @media screen and (max-width: $tab-screen) {
    padding: 20px 24px;
  }

  @media screen and (max-width: $mobile-screen) {
    padding: 16px 12px;
  }
}

.table-theme {
  vertical-align: middle;

  &:last-child {
    margin-bottom: 0 !important;
  }

  th {
    font-size: 16px;
    font-weight: 600;
    font-size: 16px;
    font-weight: 600;
    padding: 24px 20px;
    border: 0;
    vertical-align: middle;

    @media screen and (max-width: $mobile-screen) {
      font-size: 18px;
      padding: 16px 12px;
    }
  }

  td {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px;
    border: 0;
    border-right: 3px solid #fff;
    vertical-align: middle;

    @media screen and (max-width: $mobile-screen) {
      padding: 7px 12px;
      border-width: 2px;
    }
  }

  .form-select {
    background-color: transparent;
    border: 0;
  }


  .tooltip {
    .tooltip-inner {
      background: #000 !important;
      color: #fff !important;
    }

    .tooltip-arrow {
      padding: 0;
      padding: 4px 8px;
      background-color: transparent;
      border-color: #000 !important;
    }
  }
}

.table-stripedTheme {
  tr:nth-of-type(odd) td {
    background: #e5e5e5;
  }
}

.close-svg {
  fill: rgb(220, 53, 69);
  width: 26px;
  height: 34px;

  @media screen and (max-width: $mobile-screen) {
    width: 20px;
    height: 28px;
  }
}


.form-theme {
  padding: 28px 40px;

  label {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;

    @media screen and (max-width: $tab-screen) {
      font-size: 16px;
      font-size: 16px;
    }

    @media screen and (max-width: $mobile-screen) {
      font-size: 14px;
      font-size: 14px;
    }

    &.form-check-label {
      font-size: 14px;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .form-select,
  .form-control,
  .formGroup input {
    color: #495057;
    font-size: 16px;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #8c8c8c;
    // border-radius: 0;
    // padding: 13px 20px;
    // border-radius: 0;
    // padding: 13px 20px;
    // max-width: 230px;



    @media screen and (max-width: $mobile-screen) {
      font-size: 17px;
      padding: 8px 12px;
    }

    &:disabled {
      background-color: #e9ecef;
    }

  }

  .input-group-text {
    background: #fff;
    border-radius: 0;
    border: 1px solid #8c8c8c;
  }

  // &.bg-gray {

  //   .form-select,
  //   .form-control,
  //   .formGroup input {
  //     border-color: transparent;
  //   }

  //   .input-group-text {
  //     background: #fff;
  //     border-color: transparent;
  //   }
  // }

  // .formGroup {
  //   padding: 0 !important;

  //   span.error {
  //     width: 100%;
  //     display: block;
  //     margin-top: 4px;
  //   }
  // }

  // .label {
  //   display: none !important;
  // }
  .h-58 {
    height: 58px;
  }

  .br-right {
    border-right: 1px solid #8c8c8c;
  }
}

.form-check-input {
  &[type=checkbox] {
    border: 1px solid #8c8c8c;
    border-radius: 0;
  }

  &:checked {
    background-color: #f36b21;
    border-color: #f36b21;
  }

  &:focus {
    border-color: #f36b21;
    box-shadow: 0 0 0 0.25rem rgb(243 107 33 / 25%);
  }
}

.btn-gp {
  .btn {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.pagination {
  margin-bottom: 0;

  svg {
    width: 8px;
    height: 16px;
    fill: #000;
  }

  .page-item {
    &.active {
      .page-link {
        color: #fff;
        background: #f36b21;
        border-color: #f36b21;
      }
    }
  }

  .page-link {
    color: #000;
    padding: 8px 15px;
    border-color: #8c8c8c;
    border-radius: 0 !important;

    &:hover {
      color: #fff;
      background: #f36b21;
      border-color: #f36b21;

      svg {
        fill: #fff;
      }
    }
  }
}

.custom-switch-theme {
  display: inline-block;
  position: relative;
  width: 58px;
  height: 38px;
  padding: 12px;
  line-height: 1;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  label {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 38%);
      top: 0;
      left: 0;
      border-radius: 30px;
      transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      background: #fff;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
      transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    }
  }

  input:checked~label::before {
    background: #f36b21;
    opacity: .5;
  }

  input:checked~label::after {
    transform: translate(100%, -50%);
    background: #f36b21;
  }
}

.modal {

  .modal-header,
  .modal-footer {
    padding: 14px 24px;

    @media screen and (max-width: $mobile-screen) {
      padding: 12px 16px;
    }
  }

  .modal-body {
    padding: 20px 24px;

    @media screen and (max-width: $mobile-screen) {
      padding: 14px 16px;
    }
  }

  p {
    font-size: 16px;
    font-size: 16px;
  }

  label.form-check-label {
    font-size: 20px;

    @media screen and (max-width: $tab-screen) {
      font-size: 18px;
    }

    @media screen and (max-width: $mobile-screen) {
      font-size: 16px;
    }
  }

  .form-check-input[type=checkbox] {
    margin-top: 8px;

    @media screen and (max-width: $tab-screen) {
      margin-top: 6px;
    }

    @media screen and (max-width: $mobile-screen) {
      margin-top: 4px;
    }
  }
}

.accordion-theme {
  .accordion-item {
    margin-bottom: 20px;
    border: 0;



    .accordion-button {
      color: #f36b21;
      font-size: 20px;
      font-weight: bold;
      padding: 20px 24px;
      border: 1px solid #f16a2e;
      border-radius: 10px !important;

      @media screen and (max-width: $mobile-screen) {
        font-size: 18px;
        padding: 12px 12px;
      }

      &:not(.collapsed) {
        color: #fff;
        background: #f16a2e;
        box-shadow: none
      }

      &:not(.collapsed)::after {
        filter: brightness(0) invert(1);
      }
    }

    .accordion-body {
      padding: 20px 24px;

      @media screen and (max-width: $mobile-screen) {
        padding: 10px 12px;
      }


      .box-full {
        padding: 0 24px;
        margin: 0 -24px;

        @media screen and (max-width: $mobile-screen) {
          padding: 0 12px;
          margin: 0 -12px;
        }
      }
    }
  }
}

.card-theme {
  border: 1px solid #f16a2e;
  border-radius: 10px;
  overflow: hidden;

  .card-header {
    color: #fff;
    background: #f16a2e;
    font-size: 20px;
    font-weight: bold;
    padding: 20px 24px;
  }


}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #f16a2e;
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #f16a2e;
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  font-size: 20px;
  color: inherit;

  @media screen and (max-width: $tab-screen) {
    font-size: 18px;
  }

  @media screen and (max-width: $mobile-screen) {
    font-size: 16px;
  }
}

.form-check {
  padding-left: 30px;
  margin-bottom: 1rem;

  @media screen and (max-width: $tab-screen) {
    padding-left: 28px;
  }

  @media screen and (max-width: $mobile-screen) {
    padding-left: 26px;
  }
}

.form-check .form-check-input {
  margin-left: -30px;
  width: 20px;
  height: 20px;
  margin-top: 6px;

  @media screen and (max-width: $tab-screen) {
    margin-left: -28px;
  }

  @media screen and (max-width: $mobile-screen) {
    margin-left: -26px;
  }

  @media screen and (max-width: $tab-screen) {
    width: 18px;
    height: 18px;
    margin-top: 5px;
  }

  @media screen and (max-width: $mobile-screen) {
    width: 16px;
    height: 16px;
    margin-top: 4px;
  }
}

.table-addMore th,
.table-addMore td {
  padding: 8px;
}

.table-addMore th .btn {
  white-space: nowrap;
}

.border_top {
  border-top: 1px solid #8c8c8c;
}

.btn-gpTheme .btn+.btn {
  margin-left: 24px;

  @media screen and (max-width: $laptopSmall-screen) {
    margin-left: 16px;
  }

  @media screen and (max-width: $mobile-screen) {
    margin-left: 4px;
  }

}

.ngx-pagination {
  display: flex;
  justify-content: flex-end;

  li {
    color: #000 !important;
    padding: 8px 15px !important;
    border: 1px solid #8c8c8c;
    border-radius: 0 !important;
    margin-right: 0 !important;


    &.pagination-previous,
    &.pagination-next {
      span {
        font-size: 0;
        display: block;


        &:before {
          font-size: 18px;
          line-height: 1.3;
          margin: 0 !important;
          content: "\f100";
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          display: block;
        }

        .show-for-sr {
          display: none;
        }
      }

      &:before,
      &:after {
        display: none !important;
      }
    }

    &.pagination-next {
      span {
        &:before {
          content: "\f101";
        }
      }
    }

    &.disabled {
      color: #cacaca !important;
      border-color: #cacaca !important;
    }

    &.current {
      color: #fff !important;
      background: #f36b21;
      border-color: #f36b21;
    }
  }

}

.textarea-wrapper {
  position: relative;
}

.is-invalid~.invalid-feedback+.remaning {
  position: absolute;
  bottom: 0;
  right: 0;
  top: auto;
  font-size: 14px;
  color: #a2a5a4;
}

.remaning {
  font-size: 14px;
  color: #a2a5a4;
  text-align: right;
  display: block;
}

.verify-field {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #8c8c8c;
  text-align: center;
  color: #000;
  font-size: 2.0460358056265986rem;
  outline: none;
}

span.select2-container.select2-container--default {
  width: 100% !important;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: flex !important;
  flex-wrap: wrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  display: block !important;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: #f4f4f4 !important;
  border-radius: 8px;
  height: auto !important;
  width: 100% !important;
  padding: 0px 10px !important;

}

.select2-container--default .select2-selection--multiple {
  background: #f4f4f4 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow: hidden;
}

.accordion-button:focus {
  box-shadow: none !important;
}