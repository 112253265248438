$laptop-screen: 1200px;
$laptopSmall-screen: 1399px;
$laptop79-screen: 1279px;
$tab-screen: 1199px;
$tabSmall-screen: 991px;
$mobile-screen: 767px;
$sm-screen: 575px;


@mixin theme($theme) {
  background-color: $theme;
  border: 1px solid $theme;
}

@mixin flex-all($align:center, $justify: center) {
  display: flex;
  flex-wrap: wrap;
  align-items: $align;
  justify-content: $justify;
}

@mixin absolute($top:0, $left: 0) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-$top, -$left);
}

@mixin absolute-all {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@mixin img-all($fit:contain, $position: center center) {
  width: 100%;
  height: 100%;
  object-fit: $fit;
  object-position: $position;
}


@mixin btn-theme($color, $bg, $hoverBg) {
  color: $color;
  background: $bg;
  border-color: $bg;

  &:hover {
    color: $color;
    background: $hoverBg;
  }
}

%default-btn {
  display: inline-block;
  font-size: 18px;
  line-height: 1.273;
  font-weight: 400;
  border-radius: 14px;
  padding: 15px 20px;
  cursor: pointer;
  outline: none;
  text-transform: capitalize;
  min-width: 175px;

  @media screen and (max-width: $mobile-screen) {
    font-size: 20px;
    padding: 10px 16px;
    min-width: auto;
  }

  @media screen and (max-width: $sm-screen) {
    font-size: 18px;
  }

}